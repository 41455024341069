import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";

function Footer() {
  const data = useStaticQuery(graphql`
    query {
      tw_logo: file(relativePath: { eq: "tw_logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fb_logo: file(relativePath: { eq: "Facebook_Logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      in_logo: file(relativePath: { eq: "Instagram_logo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      note_logo: file(relativePath: { eq: "note_logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Box bgcolor="#ffffff">
        <Box
          padding="80px 0 40px"
          minWidth="290px"
          maxWidth="354px"
          width="80%"
          margin="auto"
        >
          <Box marigin="auto" textAlign="center" py={10}>
            <Link to="https://www.instagram.com/laki7980/">
              <Img
                style={{
                  width: "15%",
                  margin: "auto",
                  // height: "clamp(23.92px, 10rem, 35.32px)",
                  // width: "clamp(23.92px, 10rem, 35.32px)",
                }}
                fluid={data.in_logo.childImageSharp.fluid}
              />
            </Link>
          </Box>
        </Box>
        <Box bgcolor="#63442B">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <WrapTypo color="#FFFFFF" xsfs="10px" mdfs="15px" lgfs="15px">
                Copyright © Laki hair All Rights Reserved.
              </WrapTypo>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
