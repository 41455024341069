import React from "react";
import { Helmet } from "react-helmet";

const Head = () => {
  return (
    <>
      <Helmet>
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.9.0/slick/slick.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.9.0/slick/slick-theme.css"
        />
        <title>山形市のプライベート美容室|Laki Hair -ラキヘアー-</title>
        {/* ogp */}
        <meta
          name="description"
          content="山形市のプライベート美容室|Laki Hair -ラキヘアー-"
        />
        <meta
          property="og:title"
          content="Laki Hair -ラキヘアー-|山形市のプライベート美容室"
        />
        <meta property="og:description" content="" />
        <meta property="og:url" content="" />
        <meta property="og:image" content="https://gs/images/ogp.png" />
        <meta name="twitter:card" content="" />
        <meta property="og:type" content="website" />
        <meta name="google" content="notranslate" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap"
          rel="stylesheet"
        />
        {/* ogp */}
      </Helmet>
    </>
  );
};

export default Head;
