import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import WrapTypo from "../components/WrapTypo";
import Drawer from "@material-ui/core/Drawer";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const sp_mtSize = "40px";
  const data = useStaticQuery(graphql`
    query {
      menuBar: file(relativePath: { eq: "menu2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      close: file(relativePath: { eq: "close2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Lakilogo: file(relativePath: { eq: "Lakilogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const returnTop = () => {
    window.scrollTo({
      top: 1260,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Hidden smDown>
        <Box width="70%" margin="auto" textAlign="center" py={"24px"}>
          <Grid container wrap="wrap">
            {/* <Grid item xs={4} justify="center">
              <Button
                onClick={returnTop}
                style={{ margin: "auto", width: "100%" }}
              >
                <WrapTypo isCooperItalic>
                  GSテンプレート Official Website
                </WrapTypo>
              </Button>
            </Grid> */}
            <Grid item xs={2}>
              <AnchorLink to="/#menu" stripHash>
                <WrapTypo fs="30px" AmaticSC color="#4A2609">
                  MENU
                </WrapTypo>
              </AnchorLink>
            </Grid>
            <Grid item xs={4}>
              <AnchorLink to="/#access" stripHash>
                <WrapTypo fs="30px" AmaticSC color="#4A2609">
                  ACCESS&INFO
                </WrapTypo>
              </AnchorLink>
            </Grid>
            <Grid item xs={4}>
              <AnchorLink to="/campaign" stripHash>
                <WrapTypo fs="30px" AmaticSC color="#4A2609">
                  CAMPAIGN
                </WrapTypo>
              </AnchorLink>
            </Grid>
            <Grid item xs={2}>
              <AnchorLink to="/#instagram" stripHash>
                <WrapTypo fs="30px" AmaticSC color="#4A2609">
                  INSTAGRAM
                </WrapTypo>
              </AnchorLink>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box width="90%" m={"auto"} pt="32px">
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <WrapTypo isCooperItalic>
                {/* <Link to="/" style={{ color: "#717171" }}>
                  GSテンプレート
                  <br />
                  Official Website
                </Link> */}
              </WrapTypo>
            </Grid>
            <Grid item xs={2}>
              {isOpen ? (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen((c) => !c)}
                >
                  <Box style={{ width: "100%", maxWidth: "100px" }}>
                    <Img fluid={data.close.childImageSharp.fluid} />
                  </Box>
                </Button>
              ) : (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen((c) => !c)}
                >
                  <Box style={{ width: "70%", maxWidth: "80px" }}>
                    <Img fluid={data.menuBar.childImageSharp.fluid} />
                  </Box>
                </Button>
              )}
            </Grid>
          </Grid>
          <Drawer
            anchor={"left"}
            open={isOpen}
            style={{ width: "100%" }}
            transitionDuration="1500"
          >
            <Box width="100vw" height="100vh" bgcolor="#FFFFFF">
              <Box width="90%" margin="auto" pt="32px">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={6}>
                    <Link
                      to="/"
                      style={{ color: "#717171" }}
                      onClick={() => setOpen((c) => !c)}
                    >
                      <Img
                        fluid={data.Lakilogo.childImageSharp.fluid}
                        style={{
                          width: "100%",
                          margin: "auto",
                          textAlign: "center",
                        }}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={2}>
                    {isOpen ? (
                      <Button
                        style={{
                          width: "100%",
                          maxWidth: "126px",
                          margin: "auto",
                        }}
                        onClick={() => setOpen((c) => !c)}
                      >
                        <Box style={{ width: "50%", maxWidth: "20px" }}>
                          <Img fluid={data.close.childImageSharp.fluid} />
                        </Box>
                      </Button>
                    ) : (
                      <Button
                        style={{
                          width: "100%",
                          maxWidth: "126px",
                          margin: "auto",
                        }}
                        onClick={() => setOpen((c) => !c)}
                      >
                        <Box style={{ width: "70%", maxWidth: "40px" }}>
                          <Img fluid={data.menuBar.childImageSharp.fluid} />
                        </Box>
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  height="80vh"
                >
                  <Box width="100%" textAlign="center">
                    <WrapTypo AmaticSC color="#4A2609" mt={sp_mtSize} fs="35px">
                      <Link
                        to="/#menu"
                        style={{ color: "#4A2609" }}
                        onClick={() => setOpen(false)}
                      >
                        MENU
                      </Link>
                    </WrapTypo>
                    <WrapTypo AmaticSC color="#4A2609" mt={sp_mtSize} fs="35px">
                      <Link
                        to="/#access"
                        style={{ color: "#4A2609" }}
                        onClick={() => setOpen(false)}
                      >
                        ACCESS&INFO
                      </Link>
                    </WrapTypo>
                    <WrapTypo AmaticSC color="#4A2609" mt={sp_mtSize} fs="35px">
                      <Link
                        to="/campaign"
                        style={{ color: "#4A2609" }}
                        onClick={() => setOpen(false)}
                      >
                        CAMPAIGN
                      </Link>
                    </WrapTypo>
                    <WrapTypo AmaticSC color="#4A2609" mt={sp_mtSize} fs="35px">
                      <Link
                        to="/#instagram"
                        style={{ color: "#4A2609" }}
                        onClick={() => setOpen(false)}
                      >
                        INSTAGRAM
                      </Link>
                    </WrapTypo>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Hidden>
    </>
  );
};

export default Header;
